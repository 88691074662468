import React from 'react'

import { css } from "@emotion/core"

const Form = (props) => {

    return (
    <div css={contactUs}>
        <h2><span role="img" aria-label="camera">📮</span> Contactez nous</h2>
        <form name={props.formName || "Contact"} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci">
            <div className="form-container">
                <label htmlFor="first-name" className="field half-width half-width-left">
                    <span>Prénom</span>
                    <input id="first-name" type="text" name="first-name" aria-label="first-name" placeholder="Emilie" required />
                </label>

                <label htmlFor="last-name" className="field half-width half-width-right">
                    <span>Nom</span>
                    <input id="last-name" type="text" name="last-name" aria-label="last-name" placeholder="Duvalais" required />
                </label>

                <label htmlFor="email" className="field half-width half-width-left">
                    <span>Email</span>
                    <input id="email" type="email" name="email" aria-label="email" placeholder="emilie@duvalais.ch" required />
                </label>

                <label htmlFor="phone" className="field half-width half-width-right">
                    <span>Votre téléphone</span>
                    <input id="phone" type="phone" name="phone" aria-label="phone" placeholder="0791234567" />
                </label>

                <label className="field half-width half-width-left" htmlFor="localisation">
                    <span>Votre localisation (où êtes-vous basé.e ?)</span>
                    <input id="localisation" type="text" name="localisation" aria-label="localisation" placeholder="Lausanne"/>
                </label>

                <label className="field full-width" htmlFor="message">
                    <span>Message</span>
                    <textarea id="message" type="text" name="message" aria-label="message" placeholder="Votre message" rows="4"></textarea>
                </label>

                <input type="hidden" name="bot-field" aria-label="bot field" />
                <input type="hidden" name="form-name"  aria-label="form name" value={props.formName || "Contact"} />

                <div>
                    <button className="send-button" type="submit" aria-label="Envoyer">Envoyer</button>
                </div>
            </div>
        </form>
    </div>
    )
}

export default Form

const contactUs = css`
  margin-top: 100px;
  border-radius: 20px;
  padding: 40px;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
`